import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {CircularProgress, Typography } from "@mui/material";
import emailjs from '@emailjs/browser';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const FormContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const Form = styled("form")({
  width: "50%",
});

const validatePhone = (value) => {
    const regex = /^(\+56[239])?\d{8}$|^(\+56[239])?\d{11}$|^\+56[239]\d{12}$/;
    return regex.test(value);
  };
  

const ContactForm = ({setSnackBarOpen}) => {
  const [formData, setFormData] = useState({
    username: "",
    phone: "",
    email: "",
    message: "",
    subject: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [phoneError, setPhoneError] = useState(false)
  const [emailError , setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)  
  const [phoneHelp, setPhoneHelp] = useState(false)
  const [sent , setSent]  = useState(false)
  const formRef = useRef();  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(name === 'phone' && !phoneError ){
      setPhoneHelp('Empezando con')
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    //console.log(formData)
  };
  

  const handleSubmitv1 = (e) => {
    setLoading(true);
        e.preventDefault();
        try {
            emailjs.sendForm('service_19xx7ph', 'template_lm5kf8h', formRef.current, 'Ktbv8ySoQFCO3oL3Q')
          .then((result) => {
              console.log(result.text);
              if(result.text == 'OK'){
                  setSent(true);
                  setSnackBarOpen(true)
                  setFormData({})
              }
          }, (error) => {
              console.log(error.text);
          });
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false);
        }
  };
  

  const handleBlurPhone = () => {
    setPhoneHelp(false)
    setPhoneError(!validatePhone(formData.phone))    
  }

  const handleBlurEmail = () => {
    setEmailError(!isEmail(formData.email))
  }

  const handleBlurName = () => {
    setNameError(formData.username.length < 5)
  }

  function isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
//   console.log('lengths: ', formData.username.length, formData.phone.length, formData.email.length)
// //console.log('name error: ', nameError)
  return (
    <FormContainer>        
      <Form sx={{flex: {xs:1, md:'none'}}} ref={formRef} onSubmit={handleSubmitv1} >
        {sent ? <Typography sx={{textAlign:'center', fontSize:20, fontWeight:'bold'}} >
            Gracias por su mensaje, responderemos a la brevedad.
            <SentimentSatisfiedAltIcon color="success" sx={{fontSize:50, display:'block', margin:'auto'}} />
            </Typography>
        :
        <div style={{height:'75vh'}}>
          <TextField
            required
            label="Nombre"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            // color={formData.username.length > 5 && 'success'}
            // focused={formData.username.length > 0}
            error={nameError}
            helperText={nameError && 'Debe ingresar un nombre'}
            onBlur={handleBlurName}
            disabled={sent}
            
          />
          <TextField
            required
            label="Teléfono"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            error={phoneError}
            helperText={phoneError ? 'Debe ser un número chileno' : phoneHelp ? phoneHelp : null}
            onBlur={handleBlurPhone} 
            color={validatePhone(formData.phone) && 'success'}
            // focused={formData.phone.length > 0}
            disabled={sent}

                     
          />
          <TextField
            required
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            type="email"
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            color={isEmail(formData.email) ? 'success' : 'warning'}
            // focused={formData.email.length > 0}
            error={emailError}
            helperText={emailError && 'Debes ingresar una email válido.'}
            onBlur={handleBlurEmail}
            disabled={sent}
          />
          <TextField
            label="Empresa"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            disabled={sent}
          />
          <TextField
            required
            label="Mensaje"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            multiline
            rows={4}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            disabled={sent}
          />
        <Button variant="contained" color="primary" 
        style={sent? {background : 'linear-gradient(to right bottom, #b7cbb2, #47bf73)'} : {background: 'linear-gradient(to right bottom, #3480eb, #83CAF7)'}}
        onClick={handleSubmitv1}
        disabled = {sent}
        >
          {
            loading ? <CircularProgress size={15} sx={{alignSelf:'center', color:'white'}} /> : 
            sent?
            'Mensaje Enviado'
            :
            'Enviar mensaje'
          }
        </Button>
        </div>
        }
      </Form>
    </FormContainer>
  );
};

export default ContactForm;
