import React from 'react'
import useAuth from '../hooks/useAuth';
import { redirect } from 'react-router-dom';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 310,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };

const SignOutModal = ({open, setOpen}) => {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { handleSignOut, isAuthLoading } = useAuth()

    const logOut = async() => {
        await handleSignOut()
        console.log('BYE')
        redirect('/Clientes/Login')
    }
  
    return (
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" color="textPrimary" fontWeight={'bold'} align='center' >
              Cerrar sesión
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} color="textPrimary" align='center'>
              ¿Estás seguro que quieres cerrar sesión?
            </Typography>
            <Box sx={{display:'flex', justifyContent:'space-around'}} >
                <Button onClick={() => setOpen(false)}>Cancelar</Button>
                <Button color='error' onClick={logOut} sx={{width:179}}  >
                  <CircularProgress size={15} color='inherit' style={{display: isAuthLoading ? 'block' : 'none', marginRight:10,}} />
                  Cerrar sesión
                </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    );
}

export default SignOutModal
