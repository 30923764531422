import React, { useContext, useState } from "react";
import { useRef } from "react";
import { ThemeContext } from "../../context";
import "./contact.css"
import emailjs from '@emailjs/browser';
import { Button, CircularProgress } from "@mui/material";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useNavigate } from "react-router-dom";

const Contact = () => {
    const formRef = useRef();
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode; 
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate()
    
    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            emailjs.sendForm('service_19xx7ph', 'template_lm5kf8h', formRef.current, 'Ktbv8ySoQFCO3oL3Q')
          .then((result) => {
              console.log(result.text);
              setDone(true);
          }, (error) => {
              console.log(error.text);
          });
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false);
        }
    }

    return(
        <div className="c">
            <div className="c-bg"></div>
            <div className="c-wrapper">
                <div className="c-left">
                <h1 className="c-title">Bajar costos con Inteligencia Artificial</h1>
                <div className="c-info">
                    <div className="c-info-item">
                        {/* <img src={require("../../img/skype.png")} style={{width:30, height:30, marginRight:10,}} /> */}
                         Esa es nuestra misión.
                        
                    </div>
                    <div className="c-info-item">
                    <LocalPhoneIcon color={'secondary'} style={{display:'block'}} />
                        +56227604483
                    </div>
                    <div className="c-info-item">
                        <Button onClick={() => navigate('/Demostracion')} variant="contained" >
                            Pruebe nuestras demostraciones
                        </Button>
                    {/* <img src={require("../../img/location.jpeg")} style={{width:30, height:30, marginRight:10,}} /> */}
                    </div>
                </div>
                </div>
                <div className="c-right">
                    {
                        !done?
                        <>
                        <p className="c-desc">
                            <b>Cómo podemos ayudar?</b> Hablemos de su negocio
                        </p>
                        <form ref={formRef} onSubmit={handleSubmit}>
                        <input style={{backgroundColor : darkMode && "#333" }} type="text" placeholder="Nombre" name="username"/>
                        <input style={{backgroundColor : darkMode && "#333" }} type="text" placeholder="Empresa" name="subject"/>
                        <input style={{backgroundColor : darkMode && "#333" }} type="text" placeholder="Email" name="email"/>
                        <textarea style={{backgroundColor : darkMode && "#333" }} rows="5" placeholder="Mensaje" name="message" />
                        <button style={{width:100, height:48}} disabled={loading || done}  >
                            {
                                loading ? <CircularProgress size={15} sx={{alignSelf:'center', color:'white'}} /> : "Enviar"
                            
                            }
                        </button>
                        </form>
                        </>
                        :
                        <>
                        <InsertEmoticonIcon fontSize="large"/>
                        Gracias, su mensaje ha sido enviado y responderemos a la brevedad.
                        </>
                    }
                        {/* {done &&  } */}
                        {error && 'Error, intente nuevamente.'}
                </div>
            </div>
        </div>
    );
}

export default Contact;