import { useEffect, useState } from 'react';
import { signIn, signOut, getCurrentUser } from 'aws-amplify/auth';

function useAuth() {
    const [isAuthLoading, setIsAuthLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {  
        currentAuthenticatedUser();
    }
    ,[])

  async function handleLogin({ username, password }) {
    setIsAuthLoading(true);
    try {
      const {isSignedIn, nextStep,} = await signIn({ username, password });
      setIsAuthenticated(isSignedIn)
      await currentAuthenticatedUser();
    } catch (error) {
      console.log('error signing in', error);
    } finally {
        setIsAuthLoading(false);
    }
  }

  async function handleSignOut() {
    setIsAuthLoading(true);
    try {
      await signOut();
      console.log('signed out');
      setUser(null);
    } catch (error) {
      console.log('error signing out: ', error);
    } finally {
      setIsAuthLoading(false);
    }
  }

  async function currentAuthenticatedUser() {
    console.log('currentAuthenticatedUser')
    try {
      const { userId } = await getCurrentUser();
      setUser(userId);
    } catch (err) {
      console.log('error currentAuthenticatedUser: ', err);
      setUser(null)
    }
  }

  return { isAuthLoading, isAuthenticated, user, handleLogin, handleSignOut, currentAuthenticatedUser };
}

export default useAuth;