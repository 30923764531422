export function countVotes(interactions) {
    let result = [];

    interactions.forEach(interaction => {
        if (interaction?.intentions.includes('Encuesta')) {
            interaction?.slots.forEach((slot, index) => {
                let interpretation = interaction.interpretations[index];
                let slotObject = result.find(obj => obj.name === slot);

                if (slotObject) {
                    if (slotObject[interpretation]) {
                        slotObject[interpretation]++;
                    } else {
                        slotObject[interpretation] = 1;
                    }
                } else {
                    let newSlotObject = { name: slot };
                    newSlotObject[interpretation] = 1;
                    result.push(newSlotObject);
                }
            });
        }
    });

    return result;
}