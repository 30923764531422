import React, { useState } from 'react'
import './sidebar.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Dashboard } from '@mui/icons-material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link, Navigate, redirect, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Box, Skeleton, Typography, styled } from '@mui/material';
import SignOutModal from '../SignOutModal';

const Image = styled('img')({
    height: '41px',
  });

function Sidebar({style}) {
    const [loadingImage, setLoadingImage] = useState(true)
    const [showAlert, setShowAlert] = useState(false)
    const { handleSignOut } = useAuth()
    const navigate = useNavigate()

    const logOut = async() => {
        await handleSignOut()
        console.log('BYE')
        redirect('/Clientes/Login')
    }

    const handleEncuestas = () => {
        console.log('to encuestas mother fucker!!!!')
        navigate('/Clientes/Encuestas') 
    }

  return (
    <div className={'sidebar'}  >
        <SignOutModal open={showAlert} setOpen={setShowAlert} />
        <div className="top">
                <Link to={'/'} style={{textDecoration:'none'}} >
                    <Box sx={{display:'flex', flexDirection:'row', alignItems: 'center', gap:1}} >
                        {
                            loadingImage && <Skeleton variant='rectangular' sx={{width:{xs:33, md:35}, height:{xs:33, md:35}}}  />
                        }
                    <Image 
                    onLoad={() => setLoadingImage(false)} 
                    onLoadStart={() => setLoadingImage(true) } 
                    src={require('../../images/favicon.ico')} 
                    sx={loadingImage? {display:'none'} : {width: {xs:33, md:35}, height: {xs:33, md:35},}} 
                    />
                    <Typography color={'primary'} fontWeight={800} fontSize={24} >Fonoxia</Typography>
            </Box>
                </Link>

        </div>
        <hr/>
        <div className="center">
            <ul>
                <p className="title">Principal</p>
                <li>
                    <DashboardIcon className='icon' />
                    <span>Panel</span>
                </li>
            </ul>
            <ul>
                <p className="title">Lista</p>
                <Link to={'/users'} style={{textDecoration:'none'}} >
                <li>
                    <Person2OutlinedIcon className='icon' />
                    <span>Usuarios</span></li>
                </Link>
            </ul>
            <ul>
                <Link to={'/products'} style={{textDecoration:'none'}} >
                <li>
                    <StoreOutlinedIcon className='icon' />
                    <span>Empresa</span>
                </li>
                </Link>
            </ul>
            <ul>
                <li><PaymentOutlinedIcon className='icon' />
                    <span>Pagos</span></li>
            </ul>
            <ul>
                <li>
                    <LocalShippingOutlinedIcon className='icon' />
                    <span>Entregas</span></li>
            </ul>
            <ul>
                <p className="title">Utilidades</p>
                <li onClick={handleEncuestas} >
                    <BarChartOutlinedIcon className='icon' />
                    <span>Encuestas</span></li>
            </ul>
            <ul>
                <li>
                    <NotificationsNoneOutlinedIcon className='icon' />
                    <span>Notificaciones</span></li>
            </ul>
            <ul>
                <p className="title">Service</p>
                <li>
                    
                    <HealthAndSafetyOutlinedIcon className='icon' />
                    <span>Salud del Sistema</span></li>
            </ul>
            <ul>
                <li>
                    <MenuBookOutlinedIcon className='icon' />
                    <span>Registros</span></li>
            </ul>
            <ul>
                <li>
                    <SettingsOutlinedIcon className='icon' />
                    <span>Configuraciones</span></li>
            </ul>
            <ul>
                <p className="title">User</p>
                <li>
                    <PortraitOutlinedIcon className='icon' />
                    <span>Perfil</span></li>
            </ul>
            <ul>
                <li onClick={() => setShowAlert(true)}>
                    <LogoutOutlinedIcon className='icon' />
                    <span>Cerrar sesión</span>
                </li>
            </ul>
        </div>
        <div className="bottom">
            <div className="colorOption"></div>
            <div className="colorOption"></div>
            
        </div>
    </div>
  )
}

export default Sidebar