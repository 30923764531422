import React from 'react'
import Navbar from '../components/Navbar'
import { Box, Typography } from '@mui/material'
import Intro from '../components/intro/Intro'
import Description from '../components/description/Description'
import ProductCard from '../components/productCard/ProductCard'
import ProductList from '../components/productList/ProductList'
import Contact from '../components/contact/Contact'
const HomeScreen = () => {
  return (
    <div>
      <Navbar />
      <Intro />
      <Description />
      <ProductList />
      <Contact />
    </div>
  )
}

export default HomeScreen
