import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { Box, Typography } from '@mui/material'
import DemoCard from '../components/DemoCard'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CallMeModal from '../components/CallMeModal';

const demos = [
    {
        title: 'Reserva de mesa',
        phone: '+56 22 760 4457',
        instructions: <Typography><RecordVoiceOverIcon /> "Quiero reservar para 4 personas para pasado mañana a las 8" <br/> 
        O simplemente escuche a la IA y responda sus preguntas.
        </Typography>,
        description: <Typography sx={{fontWeight: '200', fontSize:14}} >
        El bot solicitará información sobre la cantidad de personas, la fecha y la hora deseada para reservar. 
        Cada respuesta será evaluada en tiempo real. <br/>
        Por ejemplo, si se solicita una mesa para más de 10 personas, el bot indicará que el máximo de personas permitidas es 10 y solicitará una nueva cantidad. <br/>
        En caso de solicitar una reserva para una hora fuera del horario de atención, el bot informará que no hay disponibilidad en ese horario y pedirá una nueva hora de preferencia.<br/><br/>
        Una vez completada la solicitud de reserva, se solicitará al cliente que confirme la información entregada. Si el cliente confirma, el bot verificará la disponibilidad en una base de datos. Si es posible, confirmará la reserva. Una vez confirmada, el restaurante podrá visualizarla en su aplicación de administración, y el cliente recibirá un SMS con un número de reserva.
        </Typography>
    },
    {
        title: 'Arriendo de grúas', 
        phone: '+56 22 760 4446',
        instructions: <Typography><RecordVoiceOverIcon />"Quiero reservar una grúa de 3 toneladas para mañana a las 8 AM en la dirección Costas de Gaviotas 41234" <br/> 
        O simplemente escuche a la IA y responda sus preguntas.
        </Typography>,
        description: <Typography sx={{fontWeight: '200'}}>
            El Bot preguntará si necesita una cotización o hacer una reserva. Luego preguntará por la capacidad de la grúa, dirección, fecha y hora. <br/>
            Una vez tenga toda la información pedirá confirmar que los datos están bien y verificará si hay disponibilidad en la base de datos.<br/>
            Si todo está en orden, el bot confirmará la reserva. En caso contrario, ofrecerá al usuario la opción de ajustar la capacidad de la grúa, la fecha o la hora para encontrar una disponibilidad adecuada.<br/>
            Si pide una cotización, preguntará por la capacidad de la grúa y la duración del arriendo. Con esa información calculará el precio y lo dirá al cliente.
        </Typography>
    },
    // {
    //     title: 'Encuesta elección presidencial',
    //     phone: 'Pida que lo llame el bot',
    //     instructions: <Typography><PhoneInTalkIcon /> El Bot lo llamará a su teléfono y pedirá responder una encuesta.</Typography>,
    //     description: <Typography sx={{fontWeight: '200'}}>
    //         El bot llamará al número solicitado y preguntará por la intención de voto en la próxima elección presidencial. <br/>
    //         Luego preguntará por la intención de voto en una eventual segunda vuelta. <br/>
    //         Cada respuesta será registrada en una base de datos y el bot agradecerá al encuestado por su tiempo. <br/>
    //         Los resultados se pueden ver en la app o el panel web de Fonoxia.<br/>
    //         Si la persona dice no querer responder la encuesta, le dirá que no hay problema y que tenga un buen día.
    //     </Typography>
    // }
]
//style={{ padding:5,width: '100%' , display: 'flex', justifyContent: 'space-between', flexWrap:'wrap'}}
const DemoScreen = () => {
    const [modalOpen, setModalOpen] = useState(false)
  return (
    <div style={{ flex: 1}}>
        <Navbar />
        <CallMeModal open={modalOpen} setOpen={setModalOpen} />
            <Typography sx={{textAlign:'center', fontSize:30, fontWeight:100}} >Pruebe nuestros ejemplos</Typography>
            <Box sx={{gap: 10, display: 'flex', flexDirection:{xs:'column', md: 'row'}, justifyContent: 'center',  paddingX:2, }} >
                {
                    demos.map((demo, index) => <DemoCard 
                                                key={index} 
                                                title={demo.title} 
                                                phone={demo.phone} 
                                                description={demo.description} 
                                                instructions={demo.instructions} 
                                                setModalOpen={setModalOpen}
                                                />)
                }
            </Box>
            <Typography sx={{fontWeight:'200', textAlign: 'center', marginTop:4}}>
                Cada filtro de datos o interacción puede ser personalizado. Por ejemplo, quizás prefiera que la cotización sea enviada por mail o SMS. <br/>
                {/* Pruebe con tranquilidad, los ejemplos fueron hechos para demostraciones, no estará arrendando una grúa o reservando una mesa. */}
            </Typography>
    </div>
  )
}

export default DemoScreen
