
import { Paper, Typography, } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Chart from '../../../components/chart/Chart'
import Featured from '../../../components/featured/Featured'
import Sidebar from '../../../components/sidebar/Sidebar'
import Widget from '../../../components/widget/Widget'
import List from '../../../components/table/Table'


import './home.scss'
import ClientNavbar from '../../../components/clientNavbar/Navbar'
import useAuth from '../../../hooks/useAuth'
import { Navigate } from 'react-router-dom'
import { generateClient } from 'aws-amplify/api';
import { listFonoxiaInteractions } from '../../../graphql/queries'
import Selector from '../../../components/Selector'
import { countVotes } from '../../../functions/surveyFunctions'
import useInteractions from '../../../hooks/useInteractions'

const ClientHomeScreen = () => {
  const [quantities, setQuantities] = useState([{intention: '', quantity: 0}])
  const [selection, setSelection] = useState('')
  const { interactions, loading, uniqueIntentions } = useInteractions()
// console.log('uniqueIntentions: ', uniqueIntentions)
  const { user, currentAuthenticatedUser } = useAuth()
  // const client = generatecl
  
  const countIntentions = (intentionToCount) => {
    return interactions.reduce((acc, interaction) => {
      return acc + (interaction.intentions.includes(intentionToCount) ? 1 : 0);
    }, 0);
  }
  
  useEffect(() => {
    if (uniqueIntentions.length > 0) {
      setQuantities(uniqueIntentions.map(intention => {
        return {intention, quantity: countIntentions(intention)}
      }
      ))
    }
  }
  , [uniqueIntentions])
  console.log('uniqueIntentions: ', uniqueIntentions)

  
  return (
    <div className='home' >
      {/* { !user && <Navigate to='/Clientes/Login' replace={true} />} */}
        <Sidebar/>
        <div className="homeContainer">
          <ClientNavbar />
          <div className="widgets">
            <Widget type='Interacciones' amount={interactions.length} loading={loading} />
            {
              loading &&
              <>
              <Widget type='Interacciones' amount={interactions.length} loading={loading} />
              <Widget type='Interacciones' amount={interactions.length} loading={loading} />
              </>
            }
            {
              quantities.map((quantity, index) => (
                <Widget key={index} type={quantity?.intention} amount={quantity?.quantity} loading={loading} />
              ))
            }
          </div>
          <div className="charts">
            <Featured items={interactions} />
            <Chart aspect={2/1}  title='Últimos 8 meses'/>
          </div>
            <Paper className='listContainer' elevation={1} >
            {/* <Typography>Ver: </Typography> */}
            <Selector items={uniqueIntentions} selection={selection} setSelection={setSelection} />
              <div className="listTitle">Últimos contactos</div>
              <List interactions={selection !== '' ? interactions.filter(interaction => interaction.intentions.includes(selection)) : interactions
              } />
            </Paper>
            
          
        </div>
    </div>
  )
}

export default ClientHomeScreen