import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { ExpandMore } from '@mui/icons-material';
import { Box, Modal, Skeleton } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import HttpIcon from '@mui/icons-material/Http';
import PlanItem from '../PlanItem';

function ProductCard({title, shortDesc, content, img, video, appstore, url, items}) {
    
    const [expanded, setExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
  return (
    <Box >
      <Modal
        open={showModal}
        onClose={()=> setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Youtube video of the product
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Description
          </Typography>
          <iframe width="408" height="583" src={`https://www.youtube.com/embed/${video}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Box>

      </Modal>
        <Card  sx={{ width: {xs: 310, md: 340}, marginBottom:2, borderRadius:5, marginX:{xs: 0, md: 2}, padding:1  }} 
          elevation={12}  
        >
        <CardHeader
          action={
            <>            
            <IconButton aria-label="settings" onClick={()=> setShowModal(!showModal)}>
              {video &&  <OndemandVideoIcon  />}              
            </IconButton>

            <IconButton aria-label="settings" onClick={() => {window.location.href = appstore}}>              
              {appstore && <AppleIcon /> }
            </IconButton>

            <IconButton aria-label="settings"  onClick={() => {window.location.href = url}}>              
              {url && <HttpIcon /> }
            </IconButton>
            </>
          }
          title={title}
          
        />
        {        
          loadingImage && <Skeleton variant="rectangular" width={350} height={194} animation="wave" />
        }
        {
          items && items.map((item, index) => (
            <PlanItem key={index} label={item} />
          ))
        }

        {/* <PlanItem /> */}
        {/* <CardMedia
          component={"img"}
          height="194"          
          // image={img}
          alt="Photo"
          style={ loadingImage ? {display:'none' } : { objectFit:'contain' }}
          onLoadStartCapture={() => console.log('START CAPTURE')}
          // onLoadStart={() => console.log('ONLOADSTART')}
          // onLoad={() => console.log('ONLOAD FINISHED')}
          onLoad={() => setLoadingImage(false)}
        /> */}
        
        
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            ${shortDesc} Mensual
          </Typography>
        </CardContent>
        {/* <CardActions disableSpacing>        
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions> */}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>          
            <Typography paragraph>
              {content}
            </Typography>
            
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  )
}

export default ProductCard