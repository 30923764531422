import { generateClient } from 'aws-amplify/api';
import { useState, useEffect, useMemo } from 'react';
import { listFonoxiaInteractions, } from '../graphql/queries';
import { onCreateFonoxiaInteraction } from '../graphql/subscriptions';

const useInteractions = (live) => {
  const [interactions, setInteractions] = useState([]);
  const [loading, setLoading] = useState(false);
  const client = generateClient();
  let createSub
  const getInteractions = async() => {
    setLoading(true);
    console.log('getting interactions')
    try {      
      const result = await client.graphql({
        query: listFonoxiaInteractions,
      });
      console.log('result✅: ', result);
      setInteractions(result.data.listFonoxiaInteractions.items);
    } catch (error) {
      console.log('error getting interactions: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getRealTimeInteractions = async() => {
    try {
        createSub = client
        .graphql({ query: onCreateFonoxiaInteraction })
        .subscribe({
          next: ({ data }) => console.log('data from subscribe🥳: ', data),
          error: (error) => console.warn(error)
        });
    } catch (error) {
      console.log('error getting realtime interactions: ', error);
    } 
  }

  useEffect(() => {
    if(false){
      console.log('getting realtime interactions 🚨')
      getRealTimeInteractions();
    } else {
      getInteractions();
    }
    return () => {
      if (createSub) {
        console.log('unsubscribing from realtime interactions👋')
        createSub.unsubscribe();
      }
    }
    
  }, []);

  const uniqueIntentions = useMemo(() => {
    return interactions.reduce((acc, interaction) => {
      interaction.intentions.forEach(intention => {
        if (!acc.includes(intention)) {
          acc.push(intention);
        }
      });
      return acc;
    }, []);
  }, [interactions]);
  

  return { interactions, loading, uniqueIntentions };
};

export default useInteractions;