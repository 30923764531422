import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CallMeModal({ open, setOpen }) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    setValue(event.target.value.startsWith('+') ? event.target.value : '+' + event.target.value);
    if (
      (event.target.value.startsWith("+569") ||
        event.target.value.startsWith("+562")) &&
      event.target.value.length === 12
    ) {
      setError(false);
      setHelperText("");
    } else {
      setError(true);
      setHelperText(
        "Debe empezar con +569 o +562 y tener 11 números"
      );
    }
  };

  const handleCall = async() => {
    setLoading(true)
    const url = 'https://5nop3svzene4tixryv4xtztwz40whvha.lambda-url.us-east-1.on.aws/'
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ABbcDDeF'
            },
            body: JSON.stringify({phoneNumber: value})
            })
            
        setSuccess(true)
    } catch (error) {
    //  console.log('error: ', error)       
    }
    finally{
        setLoading(false)
    }
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>            
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1}} >
            <Typography variant="h6" textAlign={'center'} color="textPrimary"   >
                {success ? 'Listo' : 'Número a llamar:'}
            </Typography>
            {success && <CheckCircleIcon color="success" style={{fontSize:25,}} />}
        </Box>
          <TextField
            type="tel"
            id="outlined-basic"
            label="Número"
            variant="outlined"
            onChange={handleChange}
            error={error}
            helperText={helperText}
            style={{marginTop:20, display: 'flex',  alignSelf: 'center', borderColor: !error && 'green'}}
            disabled={success}
            value={value}
            color={!error && value.length === 12  ? 'success' : 'primary'}
            // inputProps={{style: { color: !error && 'green' }}}
          />
          <Button
            onClick={!loading && value.length === 12 ? handleCall : null}
            sx={{ marginTop: 3, marginLeft:6, width: 200 }}
            variant="contained"
            color="primary"
            disabled={error || success}
          >
            {
                loading ? <CircularProgress style={{marginRight:7}} color="inherit" size={20} /> : <SmartToyIcon style={{marginRight:7}} />
            }
            {
                loading ? 'Llamando...' : success ? 'Llamada enviada' : 'Llamar'
            
            }
            
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
