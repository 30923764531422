
export function countItemsInPeriod(items, period, number) {
    const result = { count: 0, period: period };
    const now = new Date();
    const milliseconds = {
      daily: 24 * 60 * 60 * 1000,
      weekly: 7 * 24 * 60 * 60 * 1000,
      monthly: 30 * 24 * 60 * 60 * 1000, // approximating a month to 30 days
    };
  
    for (let item of items) {
      const createdAt = new Date(item.createdAt);
      if (now - createdAt <= number * milliseconds[period]) {
        result.count++;
      }
    }
  
    return result;
  }