import React from 'react'
import './navbar.scss'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { Avatar, Badge } from '@mui/material';
// import gsd from '../../assets/gsd.png';

function ClientNavbar() {
  return (
    <div className='navbar'>
      <div className="wrapper">
        <div className="search">
          <input type='text' placeholder='Búsqueda...' />
          <SearchOutlinedIcon className='icon'  />
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className='icon' />
            Español
          </div>
          <div className="item">
            <DarkModeOutlinedIcon className='icon' />
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className='icon' />
          </div>
          <div className="item">
            <Badge badgeContent={1} color={'error'} sx={{width:20, height:20}} 
              anchorOrigin={{vertical: 'top', horizontal: 'right',}} >
              <NotificationsNoneOutlinedIcon className='icon' />
            </Badge>
          </div>
          <div className="item">
          <Badge badgeContent={2} color={'error'} sx={{width:20, height:20}} 
              anchorOrigin={{vertical: 'top', horizontal: 'right',}} >
            <ChatBubbleOutlineOutlinedIcon className='icon' />
          </Badge>
          </div>
          
          <div className="item">
            <ListOutlinedIcon />
          </div>
          <div className="item">
            {/* <Avatar src={gsd} sx={{width:30, height:30 }} /> */}
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default ClientNavbar