import React, { useState } from "react";
import './description.css';
import simple from '../../images/simple.png'
import complejo from '../../images/complejo.png'
import imagen2 from '../../images/robots2.jpg'
import mobileapp from '../../images/mobile.jpg'
import webapp from '../../images/webapp.jpg'

// import description from '../../img/description.png'
import { Box, Skeleton, Grid, useTheme, useMediaQuery, ListItem, ListItemAvatar, Avatar, ListItemText, List } from "@mui/material";
import VoicemailIcon from '@mui/icons-material/Voicemail';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
// import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ImageModal from "../ImageModal";

const Description = () => {
    const [loadingImage, setLoadingImage] = useState(true)
    const [loadingImage2, setLoadingImage2] = useState(true)
    const [showImageModal, setShowImageModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClickedImage = (image) => {
        setSelectedImage(image)
        setShowImageModal(true)
    }

    return (
        <div className="description">
            <ImageModal open={showImageModal} setOpen={setShowImageModal} source={selectedImage} />
            <div className="description-left">
                <div className="description-card bg"></div>
                <div className="description-card">
                    <img src={imagen2} alt='robots' className="description-image" width={200} height={200}  />
            </div>
            </div>
            <div className="description-right">
                <h1 className="description-title">Mucho más que una central de llamadas.</h1>
                <p className="description-subtitle">
                    Automatiza tu negocio con inteligencia artifical
                </p>
                <List dense sx={{marginBottom:-2}}>
                    <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                        <LocalPhoneOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                        <ListItemText primary="Lee o escucha al cliente y ejecuta: Consultas a bases de datos o ejecuta tareas y responde." />
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                        <SupportAgentIcon />
                        </Avatar>
                    </ListItemAvatar>
                        <ListItemText primary="Haz llamadas o envía mensajes: Confirma asistencias, haz encuestas o sólo comunica." />
                    </ListItem>
                    
                </List>
                
                {/* <p className="description-subtitle">
                    Funciones:
                </p> */}
                <List dense sx={{marginBottom:5}}>
                    <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                        <LocalPhoneOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                        <ListItemText primary="Agrega número internacional, 800 o local a tu negocio." />
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                        <AirlineStopsOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                        <ListItemText primary="Transfiere llamados a tu celular o equipo de trabajo." />
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                        <WatchLaterOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                        <ListItemText primary="Establece un horario de atención." />
                    </ListItem>
                    {/* <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                        <VoicemailIcon />
                        </Avatar>
                    </ListItemAvatar>
                        <ListItemText primary="Graba y reproduce llamadas." />
                    </ListItem> */}
                    <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                        <AppShortcutOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                        <ListItemText primary="Revisa llamadas en nuestra app para iOS y Android, o en el panel de administración web." />
                    </ListItem>
                </List>
                
            <Grid container sx={{marginTop:-15,}} spacing={3} direction={isSmallScreen ? 'column' : 'row'}  wrap="wrap" >
                {/* First Award */}
                <Grid item xs={12} sm={6}  >
                    <button style={{backgroundColor:'transparent'}} onClick={() => handleClickedImage(webapp)}>
                        <div className="description-award" >
                            {
                                loadingImage && <Skeleton variant="rectangular" width={250} height={250} animation="wave" />
                            }
                            <img src={webapp} className="description-award-image" height={250} width={250}
                                onLoadStart={() => setLoadingImage(true)}
                                onLoad={() => setLoadingImage(false)}
                                style={loadingImage ? { display: 'none' } : { display: 'block' }}
                            />
                            <div className="description-award-text">
                                <h4 className="description-award-title">Panel Web<br/></h4>
                                {/* Rest of your code */}
                            </div>
                        </div>

                    </button>
                </Grid>
                
                {/* Second Award */}
                <Grid item xs={12} sm={6}>
                <button style={{backgroundColor:'transparent'}} onClick={() => handleClickedImage(webapp)}>
                    <div className="description-award-finalone">
                        {
                            loadingImage2 && <Skeleton variant="rectangular" width={250} height={250} animation="wave" />
                        }
                        {/* <img src={complejo} className="description-award-image" height={150} width={150}
                                onLoadStart={() => setLoadingImage2(true)}
                                onLoad={() => setLoadingImage2(false)}
                                style={loadingImage2 ? { display: 'none' } : { display: 'block' }}
                            /> */}
                        <img src={mobileapp} className="mobile-image" 
                            onLoadStart={() => setLoadingImage2(true)}
                            onLoad={() => setLoadingImage2(false)}
                            style={loadingImage2 ? { display: 'none' } : { display: 'block' }}
                        />
                        <div className="description-award-text">
                            <h4 className="description-award-title">Fonoxia <br/> App</h4>
                            {/* Rest of your code */}
                        </div>
                    </div>
                </button>
            </Grid>
            </Grid>
        
            </div>
            
        </div>
    );
}

export default Description;