import React from "react";
// import Product from "../product/Product";
import "./productList.css";
import ProductCard from "../productCard/ProductCard";
import { Typography } from "@mui/material";

//Falta noticias, Signal
const products = [ 
  // {
  //   id: 24,
  //   title: "Pyme",
  //   shortDesc: "Create and edit images with different AI models.",
  //   content: "Check out this other images",
  //   //img: require("../../img/aivinci.jpg"),
  //   // video: 'LyRxRC7gOpM',
  //   appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
  //   items: [
  //     "Reconocimiento de lenguaje natural",
  //     "Configura horarios de atención", 
  //     "Número local", 
  //     "3 opciones de transferencia.", 
  //     // "Grabación de llamadas", 
  //     // "Aplicación para iOS y Android", 
  //   ],
  //   price: "0.7 UF + IVA"
  // }, 
  {
    id: 26,
    title: "Básico",
    shortDesc: "Recibe llamadas y transfiere hasta un máximo de 3 opciones.",
    content: "Check out this other images",
    items: [
      "Reconocimiento de lenguaje natural", 
      "Configura horarios de atención", 
      "Número local (+562) ",
      "3 opciones de transferencia", 
      // "Aplicación para iOS y Android", 
    ],
    price: "1 UF + IVA"
    //img: require("../../img/restaurant.jpg"),
    // video: 'LyRxRC7gOpM',
    // appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
  },
  {
    id: 25,
    title: "Intermedio",
    shortDesc: "Enhance images using generative AI.",
    content: "Check out this other images",
    items: [
      "Reconocimiento de lenguaje natural",
      "Configura horarios de atención", 
      "Número local o gratis (Línea 800)  ", 
      "5 opciones de transferencia", 
      // "Grabación de llamadas", 
      "Aplicación para iOS y Android", 
      "Ejecuta funciones",
      "2 intenciones (Ej: Reservar, cotizar)",
      "SMS, Whatsapp, Telegram o e-mail",
    ],
    price: "2 UF + IVA"
    //img: require("../../img/pixelboost.jpg"),
    // video: 'LyRxRC7gOpM',
    // appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
  },
  {
    id: 24,
    title: "Avanzado",
    shortDesc: "Create and edit images with different AI models.",
    content: "Check out this other images",
    //img: require("../../img/aivinci.jpg"),
    // video: 'LyRxRC7gOpM',
    // appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
    items: [
      "Reconocimiento de lenguaje natural",
      "Configura horarios de atención", 
      "Número local o gratis (Línea 800)  ", 
      "10 opciones de transferencia", 
      // "Grabación de llamadas", 
      "Aplicación para iOS y Android", 
      "Ejecuta funciones",
      "4 intenciones (Ej: Reservar, cotizar)",
      "SMS, Whatsapp, Telegram o e-mail",
      "Colas de atención",
      "Llamadas salientes",
    ],
    price: "3 UF + IVA"
  },
  
];
// console.log('products: ', products)
const ProductList = () => {
  return (
    <div className="pl">
      <div className="pl-text">
        <h1 className="pl-title">Escoja su plan</h1>
        <p className="pl-desc">
          O armemos uno a su medida. <br/>
        </p>
      </div>
      <div className="pl-list">
        {products.map((prod, index) => (
          <ProductCard
            title={prod.title}
            shortDesc={prod.price}
            content={prod.content}
            key={index}
            img={prod.img}
            video={prod.video}
            appstore={prod.appstore}
            url={prod.url}
            items={prod.items}
          />
        ))}
      </div>
        <Typography style={{fontWeight:'100'}}>
        Para llamadas el precio es de 30 pesos el minuto. Para SMS es 60 pesos cada uno. 
        <br/>
        Descuento de 20% por pago anual.
        </Typography>
    </div>
  );
};

export default ProductList;
