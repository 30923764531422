import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { styled } from "@mui/material/styles";
import Navbar from '../../components/Navbar'
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import useAuth from '../../hooks/useAuth';
import { Navigate, redirect } from "react-router-dom";

const FormContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const Form = styled("form")({
  width: "30%",
});

function LoginScreen() {
  const [formData, setFormData] = useState({username: '', password: ''});
  const [errors, setErrors] = useState({username: false, password: false});
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const formRef = useRef();  
  // const navigate = useNavigate();
  const { handleLogin, handleSignOut, currentAuthenticatedUser, user } = useAuth();
  console.log('user: ', user)

useEffect(() => {
  currentAuthenticatedUser();

},[user])

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async(e) => {
    setLoading(true);
    try {
      const response = await handleLogin({username: formData.username, password: formData.password});
      console.log('signIn')
      redirect('/Clientes/Inicio')
    } catch (error) {
      
    } finally{
      setLoading(false);
    }

    console.log('submit')
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  return (
    <Box sx={{height:'100vh'}} >
      {user && <Navigate to={'/Clientes/Inicio'} replace={true} />}
      <Navbar />
      <Typography sx={{textAlign:'center', fontSize:30, fontWeight:600}} >Inicio de sesión de clientes</Typography>      
      <FormContainer>
      <Form sx={{flex: {xs:1, md:'none'}}} ref={formRef} onSubmit={handleSubmit} >
        <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          
            required
            label="Usuario"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ width: "100%", my: 1 }}
            // color={formData.username.length > 5 && 'success'}
            // focused={formData.username.length > 0}
            error={errors.username}
            helperText={errors.username && 'Debe ingresar un nombre'}
            // onBlur={handleBlurName}
            // disabled={sent}
          />
        <TextField            
            required
            // type='password'
            label="Contraseña"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ width: "100%", my: 1 }}
            // color={formData.username.length > 5 && 'success'}
            // focused={formData.username.length > 0}
            error={errors.password}
            helperText={errors.password && 'Debe ingresar una contraseña'}
            // onBlur={handleBlurName}
            // disabled={sent}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>

              ),
            }}
          />

        <Button variant="contained" color="primary" 
          style={sent? {background : 'linear-gradient(to right bottom, #b7cbb2, #47bf73)'} : {background: 'linear-gradient(to right bottom, #3480eb, #83CAF7)'}}
          onClick={handleSubmit}
          disabled = {sent}
          >
            {
              loading ? <CircularProgress size={15} sx={{alignSelf:'center', color:'white'}} /> : 
              sent?
              'Mensaje Enviado'
              :
              'Iniciar Sesión'
            }
          </Button>
      </Form>
      {/* <Button variant="contained" color="primary" onClick={handleSignOut}>
        Salir
      </Button> */}
        
      </FormContainer>
    </Box>
  )
}

export default LoginScreen