import {
  Button,
  Divider,
  Paper,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DemoCard = ({
  title,
  phone,
  description,
  instructions,
  setModalOpen,
}) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handlePhoneClick = (phoneNumber) => {
    // const phoneNumber = '+56944334862'; // Replace with the desired phone number

    if (
      /(android|webos|iphone|ipad|ipod|blackberry|windows phone|Macintosh)/i.test(
        navigator.userAgent
      )
    ) {
      // Mobile device, initiate a phone call
      window.location.href = `tel:${phoneNumber}`;
    } else {
      // Non-mobile device, copy phone number to clipboard
      navigator.clipboard
        .writeText(phoneNumber)
        .then(() => {
          setSnackBarOpen(true);
        })
        .catch((error) => {
          console.error("Failed copying:", error);
        });
    }
  };

  const handleClick = () => {
    if(title === 'Encuesta elección presidencial'){
      setModalOpen(true);
    }
    else {
      handlePhoneClick(phone);
    }
  }

  return (
    <div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <SnackbarContent
          message={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" align="center">
                Número copiado
              </Typography>
              <CheckCircleIcon style={{ marginLeft: "0.5em" }} />
            </div>
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </Snackbar>
      <Paper
        elevation={5}
        sx={{
          width: { xs: "92%", md: 400 },
          padding: { xs: 1, md: 1 },
          borderRadius: 2,
          marginLeft: { xs: 1, md: 0 },
        }}
      >
        <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
          {title}
        </Typography>
        <Button
          onClick={handleClick}
          sx={{ width: "100%" }}
          variant="text"
          color="primary"
        >
          {phone}
        </Button>
        {/* <Typography sx={{textAlign: 'center', marginBottom:1}} >{phone}</Typography> */}
        {instructions}
        <Divider sx={{ marginBottom: 2 }} />
        {description}
      </Paper>
    </div>
  );
};

export default DemoCard;
