/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFonoxiaInteraction = /* GraphQL */ `
  query GetFonoxiaInteraction($id: ID!) {
    getFonoxiaInteraction(id: $id) {
      id
      slots
      interpretations
      intentions
      phone
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFonoxiaInteractions = /* GraphQL */ `
  query ListFonoxiaInteractions(
    $filter: ModelFonoxiaInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFonoxiaInteractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slots
        interpretations
        intentions
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
