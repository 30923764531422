import React from 'react'
import './table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { formatDate } from '../../functions/dateFunctions';


function List({interactions}) {

  
  
  const rows = [
    {
      id: 1143155,
      product: 'Acer Nitro 5',
      img: 'https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_SX679_.jpg',
      customer: 'John Smith',
      date: '1 March',
      amount: 785,
      method: 'Cash on delivery',
      status: 'Approved',
    },
    {
      id: 2251084,
      product: 'Playstation 5',
      img: 'https://m.media-amazon.com/images/I/51051FiD9UL._SL1456_.jpg',
      customer: 'Michael Doe',
      date: '1 March',
      amount: 900,
      method: 'Online payment',
      status: 'Pending',
    },
  ];
  console.log('interactions: ', interactions[0]?.slots[0])

  return (
    <div className='table' >
      <TableContainer sx={{maxWidth:900 }} component={Paper} className='table'>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell className='tableCell'>ID</TableCell>
          <TableCell className='tableCell'></TableCell>
          <TableCell className='tableCell'>Intención</TableCell>
          {
            interactions[0]?.slots.map((slot, index) => {
                return <TableCell key={index} className='tableCell'>{slot}</TableCell>
              })
            }
          
            {/* <TableCell className='tableCell'>Fecha</TableCell> */}
            {/* <TableCell className='tableCell'>Amount</TableCell> */}
            {/* <TableCell className='tableCell'>Payment Method</TableCell>
            <TableCell className='tableCell'>Status</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {interactions.map((row) => (
            <TableRow
              key={row.id}>
              <TableCell className='tableCell' >{row.id.substring(0,5)}</TableCell>
              <TableCell className='tableCell' >
                <div className="cellWrapper">
                  <img src={rows[0].img} alt="" className="image" />
                  {row.product}
                </div>
              </TableCell>
              <TableCell className='tableCell'>{row.intentions[0]}</TableCell>
              <TableCell className='tableCell' >{interactions[0].slots[0] === row.slots[0] && row.interpretations[0]}</TableCell>
              <TableCell className='tableCell' >{formatDate(row.createdAt).split('|')[1] }</TableCell>
              <TableCell className='tableCell' >{formatDate(row.createdAt).split('|')[0] }</TableCell>
              <TableCell className='tableCell'>{interactions[0].slots[3] === row.slots[3] && row.interpretations[3]}</TableCell>
              <TableCell className='tableCell'>
                <span className={`status ${rows[0].status}`}>{'Completado'}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

export default List