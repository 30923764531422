import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { countVotes } from '../functions/surveyFunctions';
import { Divider } from '@mui/material';

const PollBarChart = ({data}) => {
    // console.log('data from PollBarChart: ', data)
    const graphData = countVotes(data)
    // console.log('graphData: ', graphData)
  return (
        // <ResponsiveContainer width="100vh" height="100vh" >
        <BarChart
          width={800}
          height={400}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={{marginTop: 90}}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Parisi" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
          <Bar dataKey="Michel Bachelet" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
          <Bar dataKey="Camila Vallejos" fill="#3480eb" activeBar={<Rectangle fill="gold" stroke="purple" />} />
          <Bar dataKey="Carolina Tohá" fill="#EB9F34" activeBar={<Rectangle fill="gold" stroke="purple" />} />
          <Bar dataKey="Evelyn Matthei" fill="#FF5733" activeBar={<Rectangle fill="gold" stroke="purple" />} />
          <Bar dataKey="José Antonio Kast" fill="#D12896" activeBar={<Rectangle fill="gold" stroke="purple" />} />
        </BarChart>
    //   </ResponsiveContainer>
      
  )
}

export default PollBarChart
