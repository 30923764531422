import React, { useState } from 'react';
import './intro.css';
import { Box, Skeleton } from '@mui/material';

const Intro = () => {
    const [loadingaApple, setLoadingApple] = useState(true)
    const [loadingPlay, setLoadingPlay] = useState(true)
    console.log('loading apple: ', loadingaApple)
    return (
        <div className='intro'>
            <div className='intro-left'>
                <div className='intro-left-wrapper'>
                        <h2 className='intro-saludo' >Automatiza el contacto de clientes con IA</h2>
                        <h1 className='intro-nombre'>Fonoxia</h1>
                        <div className='intro-title'>
                            <div className='intro-title-wrapper'>
                                <div className='intro-title-item'>Atiende llamadas y mensajes</div>                                
                                <div className='intro-title-item'>Ejecuta tareas y responde</div>                                
                                <div className='intro-title-item'>Con aplicación móvil</div>
                                <div className='intro-title-item'>Mail, SMS, Whatsapp y otros </div>
                                <div className='intro-title-item'>Transfiere o haz llamadas</div>
                            </div>
                        </div>
                        <p className='intro-description'>
                            {/* Monitorea los contactos descargando nuestra app para iOS y Android <br/> */}
                            Mucho más que una central de contacto. <br/>
                            IA con procesamiento de lenguaje natural, para una comunicación fácil. <br/>
                            {/* Nuetra IA puede revisar bases de datos y ejecutar funciones: <br/> */}
                            Sus clientes pueden reservar horas, revisar estado de pedido,  <br/>
                            responder encuestas, transferir llamadas y mucho más.<br/>
                            
                        </p>
                        </div>
            {/* <div className='intro-center'>
                <div className='intro-center-bg'>
                    <img src={require('../../images/mobileapp.jpg')} alt='' className='intro-image' />
                </div>
            </div> */}
            </div>
            <div className='intro-right'>
                <div className='intro-bg'>
                <img src={require('../../images/robots.JPG')} alt='' className='intro-image'/>
                </div>
             </div>
             
             <Box sx={{position:'absolute',  display:'flex', justifyContent:{xs: 'flex-start', md:'center'}, width:{xs:'100%', md:'50%'}, top:{xs:60, md:'95%'} }}>
             <button style={{ backgroundColor: 'transparent', padding:0 }} onClick={() => window.open('https://apps.apple.com/cl/app/fonoxia/id6483539066', '_blank')}>
                {
                    loadingaApple && <Skeleton variant="rectangular" width={'14.5vh'} height={'5vh'} /> 
                }
                
                <img src={require('../../images/appstores.png')} 
                        onLoadStart={() => setLoadingApple(true)} 
                        onLoad={() => setLoadingApple(false)}
                        style={loadingaApple ? {height:'5vh', display: 'none'} : {height:'5vh', display: 'block'}}
                    />
                    </button>
                    <button style={{ backgroundColor: 'transparent', padding:0 }} onClick={() => window.open('https://apps.apple.com/cl/app/fonoxia/id6483539066', '_blank')}>
                    
                {
                    loadingPlay ? <Skeleton variant="rectangular" width={'14.5vh'} height={'5vh'} /> 
                    : 
                    null
                }
                <img src={require('../../images/playstore.png')} 
                        onLoadStart={() => setLoadingPlay(true)} 
                        onLoad={() => setLoadingPlay(false)}
                        onLoadedData={() => setLoadingPlay(false)}
                        style={loadingPlay ? {height:'5vh', display: 'none'} : {height:'5vh', display: 'block'}}
                        />
            </button>
            </Box>
        </div>
    );

}

export default Intro;