import { Checkbox, Typography } from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
const items = [
    {
        id:0,
        label: 'Número local'
    },
]

const PlanItem = ({label}) => {
  return (
    <div style={{display:'flex', flexDirection:'row', alignItems: 'center', gap: 4, marginBottom:16}}>
        <CheckCircleOutlineIcon color='primary' />
        {/* <Checkbox  defaultChecked /> */}
        <Typography>{label}</Typography>
    </div>
  )
}

export default PlanItem
