import './App.css';
import HomeScreen from './screens/HomeScreen';
import { createTheme, ThemeProvider } from '@mui/material';
import { useContext, useState } from 'react';
import { ThemeContext } from './context';
import Toggle from './components/toggle/Toggle';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,  
} from "react-router-dom";
import FAQScreen from './screens/FAQScreen';
import DemoScreen from './screens/DemoScreen';
import ContactScreen from './screens/ContactScreen';
import LoginScreen from './screens/clients/LoginScreen';
import ClientHomeScreen from './screens/clients/Home/ClientHomeScreen';
import { Amplify } from 'aws-amplify';
// import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import amplifyconfig from './amplifyconfiguration.json';
import PrivateRoute from './components/PrivateRoute';
import useAuth from './hooks/useAuth';
import EncuestasScreen from './screens/clients/Encuestas/EncuestasScreen';

Amplify.configure(amplifyconfig);

const ProtectedRoute = ({ user, redirectPath = '/Clientes/Login' }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
//theverysecretpassword694100
function App() {
  const theme = useContext(ThemeContext);
  //console.log('theme: ', theme)
  const {user}  = useAuth()
  const darkMode = theme.state.darkMode;    

  const [mode, setMode] = useState('light');
  const darkTheme = createTheme({
    palette:{
      mode: mode,    
      primary: {
        main: '#3480eb',
      }, 
      secondary:{
        main: '#EB9F34'
      } 
    },
  });
  // console.log('darkMode: ', darkMode? '#222' : 'white')
  console.log('user: ', user)
  return (
    
      <Router>        
        <ThemeProvider theme={darkTheme}>  
        <div style={{backgroundColor: darkMode? '#222' : 'white', color: darkMode && 'white' }}>
            <Toggle setMode={setMode} mode={mode} />
            <Routes>
              <Route path="/Contacto" element={<ContactScreen />}/>              
              <Route path="/Preguntas-frecuentes" element={<FAQScreen />}/>                              
              <Route path="/Demostracion" element={<DemoScreen />}/>                              
              <Route path="/Clientes/Login" element={<LoginScreen />}/>                              
              <Route element={<ProtectedRoute user={true} />}>
                <Route path="/Clientes/Encuestas" element={<EncuestasScreen />}/>                              
                <Route path="/Clientes/Inicio" element={<ClientHomeScreen />}/>                              
              </Route>
              <Route path='*' element={<HomeScreen />} />                           
            </Routes>          
        </div>    
        </ThemeProvider>
      </Router>
  );
}

export default App;
