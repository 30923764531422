import React, { useState } from 'react'
import Sidebar from '../../../components/sidebar/Sidebar'
import ClientNavbar from '../../../components/clientNavbar/Navbar'
import PollBarChart from '../../../components/PollBarChart'
import { Box, Button, Paper, Typography } from '@mui/material'
import useInteractions from '../../../hooks/useInteractions'
import { countVotes } from '../../../functions/surveyFunctions'
import './encuestas.scss'
import Featured from '../../../components/featured/Featured'
import Selector from '../../../components/Selector'
import List from '../../../components/table/Table'
import { countItemsInPeriod } from '../../../functions/interactionsFunctions'


const EncuestasScreen = ({}) => {
  const [selection, setSelection] = useState('Encuesta')
  const [realtime, setRealtime] = useState(false)
  const { interactions, loading, uniqueIntentions } = useInteractions()

  return (
    <div className='home' >
      {/* { !user && <Navigate to='/Clientes/Login' replace={true} />} */}
        <Sidebar/>
        <div className="homeContainer">
          <ClientNavbar />
          <div className="widgets">
          </div>
          <div className="charts">
              <Featured items={interactions} />

            <Paper elevation={10} sx={{height: 500, width:850, borderRadius:2}} >
              {/* <Box sx={{position:'fixed'}} >
                <Button onClick={() => setRealtime(!realtime)} >{realtime ? 'Apagar' : 'Prender'} datos en vivo</Button>
              </Box> */}
              <PollBarChart data={interactions } />

            </Paper>
          </div>
            <Paper className='listContainer' elevation={1} >
            <Selector items={uniqueIntentions} selection={selection} setSelection={setSelection} />
              <div className="listTitle">Últimas encuestas</div>
              <List interactions={selection !== '' ? interactions.filter(interaction => interaction.intentions.includes(selection)) : interactions} />
            </Paper>
        </div>
    </div>
  )
}

export default EncuestasScreen
