import { Paper, Skeleton, rgbToHex } from '@mui/material'
import React from 'react'
import './widget.scss'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';


function Widget({type, amount, loading}) {
    let data;

    // const amount= 100;
    const diff = 20;

    switch(type){
        case 'user':
            data={
                title: 'RESERVA DE MESAS',
                isMoney: false,
                link: 'See all users',
                icon: (<Person2OutlinedIcon className='icon' style={{color:'crimson', backgroundColor: 'rgba(255,0,0,0.2)', }} />)
            }
            break;
        case 'order':
            data={
                title: 'RESERVA DE GRUAS ',
                isMoney: false,
                link: 'View all orders',
                icon: (<ShoppingCartOutlinedIcon className='icon' style={{color:'goldenrod', backgroundColor: 'rgba(218,165,32,0.2)', }} />)
            }
            break;
        case 'earning':
                data={
                    title: 'ENCUESTAS',
                    isMoney: true,
                    link: 'View net earnings',
                    icon: (<MonetizationOnOutlinedIcon className='icon' style={{color:'green', backgroundColor: 'rgba(0,128,0,0.2)', }} />)
                }
                break;
        case 'balance':
            data={
                title: 'COTIZACIONES',
                isMoney: true,
                link: 'See details',
                icon: (<AccountBalanceOutlinedIcon  className='icon' style={{color:'purple', backgroundColor: 'rgba(128,0,128,0.2)', }} />)
            }
            break;
        default:
            data={
                title: type,
                isMoney: false,
                link: 'Ver todo',
                icon: (<Person2OutlinedIcon className='icon' style={{color:'crimson', backgroundColor: 'rgba(255,0,0,0.2)', }} />)
            }
            break;
    }

  return (
    
    <div className='widget' >
        {
            loading && 
               <Skeleton variant='rectangular'  sx={{borderRadius:5, padding:1, height:100, width:180}} />
        }
        <Paper sx={loading ? {display:'none'} : {display:'flex', flex:1, justifyContent:'space-between', borderRadius:5, padding:1, height:100, width:180}} elevation={2} >
            <div className="left">
                <span className='title' >{data.title}</span>
                <span className='counter' >{data.isMoney && '$'}{amount || 100}</span>
                <span className='link' >{data.link}</span>
            </div>
            <div className="right">
                <div className="percentage positive">
                    <KeyboardArrowUpIcon />
                    {diff}%
                </div>
                {data.icon}
            </div>
        </Paper>
    </div>
    
  )
}

export default Widget