export function timeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();

    const diffInMilliseconds = now.getTime() - date.getTime();
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffinSeconds = Math.floor(diffInMilliseconds / 1000);

    if(diffinSeconds < 60){
        return `${diffinSeconds} segs`;
    } 
    else if (diffInMinutes < 60) {
        return `${diffInMinutes} mins`;
    } else if (diffInHours < 24) {
        return `${diffInHours}  ${diffInHours === 1 ? 'hr' : 'hrs' }`;
    } else {
        return `${diffInDays} ${diffInDays === 1 ? 'día' : 'días' }`;
    }
}

export function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };

    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString('es-ES', options);
    const formattedTime = date.toLocaleTimeString('es-ES', timeOptions).toLowerCase();

    return `${formattedDate} | ${formattedTime}`;
}