import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import ContactForm from '../components/ContactForm';
import { Snackbar, SnackbarContent, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//LOGO, DIRECCION, TELEFONO, HORARIO
//CONTACT FORM
//TEST 
function ContactScreen() {
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  return (
    <div style={{height: '100vh'}} >
      <Navbar />
      <Typography sx={{textAlign:'center', fontSize:30, fontWeight:100}} >Contacto</Typography>      
      <ContactForm setSnackBarOpen={setSnackBarOpen} />
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <SnackbarContent
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" align="center">
                Mensaje enviado
              </Typography>
              <CheckCircleIcon style={{ marginLeft: '0.5em' }} />
            </div>
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Snackbar>
      {/* <Footer /> */}
    </div>
  )
}

export default ContactScreen