import { MoreVert } from '@mui/icons-material'
import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './featured.scss'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { countItemsInPeriod } from '../../functions/interactionsFunctions';



function Featured({items}) {
    const [weekly, setWeekly] = useState(null);
    const [daily, setDaily] = useState(null);
    const [monthly, setMonthly] = useState(null);

    // console.log('countItemsInPeriod🧮 ', countItemsInPeriod(items, 'daily', 5))
    // console.log('items: ', items[0])

    useEffect(() => {
        if(items){
            setWeekly(countItemsInPeriod(items, 'weekly', 1).count)
            setDaily(countItemsInPeriod(items, 'daily', 1).count)
            setMonthly(countItemsInPeriod(items, 'monthly', 1).count)
        }
    }, [items])

  return (
    <div className='featured' >
        <Paper elevation={3} style={{borderRadius:10, height:500}} >
            <div className="top">
                <h1 className='title' >Llamadas</h1>
                <MoreVert fontSize='small' />
            </div>
            <div className="bottom">
                <div className="featuredChart">
                    <CircularProgressbar value={90} text={'90%'} strokeWidth={5} />
                </div>
                <p className="title">Llamadas aceptadas</p>
                <p className="amount">{items.length}</p>
                <p className="desc">Estadísticas pasadas</p>
                <div className="summary">
                    <div className="item">
                        <div className="itemTitle">Objetivo</div>
                        <div className="itemResult positive">
                            <KeyboardArrowUpOutlinedIcon fontSize='small' />
                            <div className="resultAmount">{160} </div>
                        </div>

                    </div>
                    <div className="item">
                        <div className="itemTitle">Semana pasada</div>
                        <div className="itemResult negative">
                            <KeyboardArrowDownOutlinedIcon fontSize='small' />
                            <div className="resultAmount">{weekly} </div>
                        </div>

                    </div>
                    <div className="item">
                        <div className="itemTitle">Último mes</div>
                        <div className="itemResult positive">
                            <KeyboardArrowUpOutlinedIcon fontSize='small' />
                            <div className="resultAmount">{monthly} </div>
                        </div>

                    </div>
                </div>
            </div>
        </Paper>
    </div>
  )
}

export default Featured