import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SimpleAccordion() {
  return (
    <div style={{height:'90vh'}} >
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿A qué se refieren con "procesamiento de lenguaje natural"?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Es un campo de la inteligencia artificial que combina modelos de lingüística computacional y aprendizaje profundo para procesar el lenguaje humano.<br/>
          Esto permite que el usuario hable como si estuviera hablando con otro ser humano y el bot pueda entender y responder de manera natural. <br/>
          A diferencia de los bots tradicionales que solo pueden entender palabras clave, nuestra IA es capaz de entender el contexto y el significado de las frases.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿A qué se refieren con una "intención"?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Una intención es un objetivo que el usuario quiere lograr.<br/> Ejemplos de intención son: Consultar el stock de un producto, reservar una hora, cotizar un servicio, confirmar asistencia, etc.<br/>
            Para esto, el usuario puede utilizar distintas palabras o frases para expresar la misma intención.<br/> Ejemplo "necesito saber si tienen stock de un producto" o "tienen disponible un producto".<br/>
            Nuestra inteligencia artificial es capaz de reconocer estas intenciones y ejecutar la acción correspondiente.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿Es posible crear un plan personalizado?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Absolutamente. Contáctanos para que podamos entender tus necesidades y ofrecerte una solución a medida.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿Pueden ofrecer números internacionales?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sí podemos ofrecer números internacionales, incluyendo líneas 800. Contáctanos para más información.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿Puedo pedir más de un número?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sí, puedes pedir números adicionales. Cada uno cuesta 15.000 mensuales. Contáctanos para más información.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}